define("discourse/plugins/discourse-policy/discourse/components/modal/policy-builder", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/utils", "@ember-compat/tracked-built-ins", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax", "discourse/lib/text", "discourse-common/helpers/i18n", "discourse/plugins/discourse-policy/discourse/components/policy-builder-form", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _utils, _trackedBuiltIns, _dButton, _dModal, _ajax, _text, _i18n, _policyBuilderForm, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PolicyBuilder extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "isSaving", [_tracking.tracked], function () {
      return false;
    }))();
    #isSaving = (() => (dt7948.i(this, "isSaving"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "flash", [_tracking.tracked]))();
    #flash = (() => (dt7948.i(this, "flash"), void 0))();
    policy = (() => this.args.model.policy || new _trackedBuiltIns.TrackedObject({
      reminder: "daily",
      version: 1
    }))();
    insertPolicy() {
      if (!this.validateForm()) {
        return;
      }
      this.args.model.toolbarEvent?.addText("\n\n" + `[policy ${this.markdownParams}]\n${(0, _i18n.default)("discourse_policy.accept_policy_template")}\n[/policy]` + "\n\n");
      this.args.closeModal();
    }
    static #_3 = (() => dt7948.n(this.prototype, "insertPolicy", [_object.action]))();
    async updatePolicy() {
      if (!this.validateForm()) {
        return;
      }
      this.isSaving = true;
      try {
        const result = await (0, _ajax.ajax)(`/posts/${this.args.model.post.id}`);
        const newRaw = this.replaceRaw(result.raw);
        if (newRaw) {
          this.args.model.post.save({
            raw: newRaw,
            cooked: (await (0, _text.cook)(result.raw)).toString(),
            edit_reason: (0, _i18n.default)("discourse_policy.edit_reason")
          });
        }
      } finally {
        this.isSaving = false;
        this.args.closeModal();
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "updatePolicy", [_object.action]))();
    get markdownParams() {
      const markdownParams = [];
      for (const [key, value] of Object.entries(this.policy)) {
        if ((0, _utils.isPresent)(value)) {
          markdownParams.push(`${key}="${value}"`);
        }
      }
      return markdownParams.join(" ");
    }
    replaceRaw(raw) {
      const policyRegex = new RegExp(`\\[policy\\s(.*?)\\]`, "m");
      const policyMatches = raw.match(policyRegex);
      if (policyMatches?.[1]) {
        return raw.replace(policyRegex, `[policy ${this.markdownParams}]`);
      }
      return false;
    }
    validateForm() {
      if ((0, _utils.isBlank)(this.policy.groups)) {
        this.flash = (0, _i18n.default)("discourse_policy.builder.errors.group");
        return false;
      }
      if ((0, _utils.isBlank)(this.policy.version)) {
        this.flash = (0, _i18n.default)("discourse_policy.builder.errors.version");
        return false;
      }
      return true;
    }
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @title={{i18n "discourse_policy.builder.title"}}
          @closeModal={{@closeModal}}
          @flash={{this.flash}}
          @flashType="error"
          class="policy-builder"
        >
          <:body>
            <PolicyBuilderForm
              @policy={{this.policy}}
              @onChange={{fn set this.policy}}
            />
          </:body>
    
          <:footer>
            {{#if @model.insertMode}}
              <DButton
                @label="discourse_policy.builder.insert"
                @action={{this.insertPolicy}}
                class="btn-primary"
              />
            {{else}}
              <DButton
                @label="discourse_policy.builder.save"
                @action={{this.updatePolicy}}
                @isLoading={{this.isSaving}}
                class="btn-primary"
              />
            {{/if}}
          </:footer>
        </DModal>
      
    */
    {
      "id": "MScQg1Gs",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"policy-builder\"]],[[\"@title\",\"@closeModal\",\"@flash\",\"@flashType\"],[[28,[32,1],[\"discourse_policy.builder.title\"],null],[30,1],[30,0,[\"flash\"]],\"error\"]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[8,[32,2],null,[[\"@policy\",\"@onChange\"],[[30,0,[\"policy\"]],[28,[32,3],[[32,4],[30,0,[\"policy\"]]],null]]],null],[1,\"\\n      \"]],[]],[[[1,\"\\n\"],[41,[30,2,[\"insertMode\"]],[[[1,\"          \"],[8,[32,5],[[24,0,\"btn-primary\"]],[[\"@label\",\"@action\"],[\"discourse_policy.builder.insert\",[30,0,[\"insertPolicy\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,5],[[24,0,\"btn-primary\"]],[[\"@label\",\"@action\",\"@isLoading\"],[\"discourse_policy.builder.save\",[30,0,[\"updatePolicy\"]],[30,0,[\"isSaving\"]]]],null],[1,\"\\n\"]],[]]],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@model\"],false,[\"if\"]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/discourse-policy/discourse/components/modal/policy-builder.js",
      "scope": () => [_dModal.default, _i18n.default, _policyBuilderForm.default, _helper.fn, _object.set, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PolicyBuilder;
});