define("discourse/plugins/discourse-policy/discourse/components/policy-group-input", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "select-kit/components/group-chooser", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _groupChooser, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PolicyGroupInput extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get selectedGroups() {
      return (this.args.groups || "").split(",").filter(Boolean);
    }
    get availableGroups() {
      return (this.site.groups || []).map(g =>
      // prevents group "everyone" to be listed
      g.id === 0 ? null : g.name).filter(Boolean);
    }
    onChange(values) {
      this.args.onChangeGroup?.(values.join(","));
    }
    static #_2 = (() => dt7948.n(this.prototype, "onChange", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <GroupChooser
          @content={{this.availableGroups}}
          @valueProperty={{null}}
          @nameProperty={{null}}
          @value={{this.selectedGroups}}
          @onChange={{this.onChange}}
        />
      
    */
    {
      "id": "yWOwYUj1",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@content\",\"@valueProperty\",\"@nameProperty\",\"@value\",\"@onChange\"],[[30,0,[\"availableGroups\"]],null,null,[30,0,[\"selectedGroups\"]],[30,0,[\"onChange\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/discourse-policy/discourse/components/policy-group-input.js",
      "scope": () => [_groupChooser.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PolicyGroupInput;
});